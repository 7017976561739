export default class CityTableItem {
    id = 0;
    status = 0;
    name = "";
    adcode = "";
    parentAdcode = "";
    parentAdcodeName = "";
    level = "";
    center = "";
    centroid = "";
    subFeatureIndex = 0;
    geometryType = "";
}
