import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import CityTableItem from "@/entity/system/master/CityTableItem";
import API from "@/api/system/master/city";

@Options({
    name: "app-system-master-city",
    components: {
        "app-select-level": SelectLevel,
        "app-draggable-listview": DraggableListview,
    },
})
export default class City extends BaseTableMixins<CityTableItem> implements Partial<BaseTableInterface<CityTableItem>> {
    //地图层级level
    private selectLevelList = [
        { label: "国家", value: "country" },
        { label: "省份", value: "province" },
        { label: "城市", value: "city" },
        { label: "区县", value: "district" },
    ];

    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new CityTableItem();
        this.editModalItem = new CityTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            parentAdcode: {
                type: "router",
                name: "adcode",
                title: "上级地区",
                parent: -1,
                value: null,
                openFn: this.parentAdcodeLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    // //列表回调
    // public listCallback(items: Array<CityTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new CityTableItem();
        this.editModalItem = new CityTableItem();
        this.editModalItem.status = 1;
        this.editModalTitle = "添加地区";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.status)) return "地区状态不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.name)) return "地区名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.adcode)) return "地区代码不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.parentAdcode)) return "上级地区不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.level)) return "地区层级不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.center)) return "地区坐标不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.centroid)) return "块区中心不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.geometryType)) return "地图类型不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改地区 (" + this.editModalItem.name + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.adcode)) return "地区ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除地区",
            message: "地区: " + this.editModalItem.name + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.adcode)) return "地区代码不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "地区状态",
            message: "地区: " + this.editModalItem.name + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.status === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.status = this.editModalItem.status === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.adcode)) return "地区ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "地区排序";
    }
    //排序提交检测
    public sortSubmitChecking(): string {
        if (!this.$tools.isEmpty(this.editModalItem.parentAdcode)) {
            this.toast.error("上级ID不能为空");
            return;
        }
        return null;
    }

    //parentAdcode搜索字段
    private parentAdcodeLevelSearchField: any = [
        { field: "adcode", name: "代码" },
        { field: "name", name: "名称" },
    ];
    //parentAdcode层级选择器打开
    public parentAdcodeLevelModalOpen() {
        (this.$refs.parentAdcodeLevel as any).openModal();
    }
    //parentAdcode层级选择器提交
    public async parentAdcodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().parentAdcode : item;
        this.tableRequestItem.lockItem.parentAdcode.value = curItem.value;
        this.tableRequestItem.lockItem.parentAdcode.title = curItem.title;
        this.tableRequestItem.lockItem.parentAdcode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get parentAdcodeSelectApi() {
        return {
            api: (query: any) => API.idSelect(query),
            parent: "parent_adcode",
            status: "status",
            name: "name",
            id: "adcode",
            cid: this.editModalItem.parentAdcode,
            mid: this.editModalItem.adcode,
            extend: true,
        };
    }
}
